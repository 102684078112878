/* navbar.module.css */
.navbar {
    position: fixed;
    top: -1px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 1rem;
    z-index: 2000;
    box-sizing: border-box;
  }

  .logo {
    height: 50px; /* Ajusta esto según tus necesidades */
    margin-right: 20px; /* Espacio entre el logo y los enlaces de navegación */
    transform: translateX(30%);
  }
  
  .navItems {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*width: 100%;*/
    flex-grow: 0.46;
  }
  
  .menuIcon {
    display: none;
  }
  
  .navbar a {
    cursor: pointer;
    color: white;
    text-decoration: none;
    padding: 0.1rem 0.25rem; /* Reducir el padding lateral */
    margin: 0 0.1rem;
  }
  
  .navbar a:hover, .navbar a.active {
    color: rgb(183, 189, 201);
  }
  
  @media (max-width: 768px) {
    .navbar {
      justify-content: flex-start;
      box-sizing: border-box;
    }

    .logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 12px;
      width: auto;
      height: 45px;
    }
  
    .navItems {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      background-color: #333;
      width: 100%;
    }
  
    .showNav {
      display: flex;
    }
  
    .menuIcon {
      display: block;
      margin-left: 0.2rem;
      font-size: 2rem;
      cursor: pointer;
    }
  
    .navbar a {
      text-align: center;
      padding: 1rem;
      width: 100%;
      display: block;
    }
  }
  